<template>
  <div>
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        Kunde hinzufügen
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>

    <!-- BODY -->
    <!-- {{ editCustomerEntry }} -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="customerForm"
        class="p-2"
        @submit="submitCustomer"
      >
        <div class="pb-1">
          <h5>Unternehmensinformationen</h5>
        </div>

        <b-form-group>
          <b-form-checkbox
            v-if="isEditMode"
            v-model="editCustomerEntry.isOrganization"
            checked="false"
            name="check-button"
            switch
          >
            Gewerblich?
          </b-form-checkbox>
          <b-form-checkbox
            v-else
            v-model="customer.isOrganization"
            checked="false"
            name="check-button"
            switch
          >
            Gewerblich?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Unternehmen"
          label-for="v-organization"
        >
          <b-form-input
            v-if="isEditMode"
            v-model="editCustomerEntry.organizationName"
            :disabled="!editCustomerEntry.isOrganization"
          />
          <b-form-input
            v-else
            v-model="customer.organizationName"
            :disabled="!customer.isOrganization"
            placeholder="Wayne Enterprise"
          />
        </b-form-group>

        <div class="pb-1 pt-1">
          <h5>Kundeninformationen</h5>
        </div>
        <!-- Anrede -->
        <b-form-group
          label="Anrede"
          label-for="v-salutation"
        >
          <v-select
            v-if="isEditMode"
            id="v-salutation"
            v-model="editCustomerEntry.salutation"
            :clearable="false"
            :options="salutationOptions"
          />
          <v-select
            v-else
            id="v-salutation"
            v-model="customer.salutation"
            :clearable="false"
            label="title"
            :options="salutationOptions"
          />
        </b-form-group>
        <!-- Vorname -->
        <b-form-group
          label="Vorname"
          label-for="v-firstname"
        >
          <validation-provider
            #default="{ errors }"
            name="Vorname"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.firstName"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.firstName"
              :state="errors.length > 0 ? false : null"
              placeholder="Bruce"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Nachname"
          label-for="v-lastname"
        >
          <validation-provider
            #default="{ errors }"
            name="Nachname"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.lastName"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.lastName"
              :state="errors.length > 0 ? false : null"
              placeholder="Wayne"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="v-email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.email"
              :state="errors.length > 0 ? false : null"
              type="email"
            />
            <b-form-input
              v-else
              v-model="customer.email"
              :state="errors.length > 0 ? false : null"
              type="email"
              placeholder="bruce@wayne.de"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="pb-1 pt-1">
          <h5>Kontakt</h5>
        </div>

        <b-form-group
          label="Telefon"
          label-for="phoneNumber"
        >
          <validation-provider
            #default="{ errors }"
            rules="integer"
            name="Telefon"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.phoneNumber"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.phoneNumber"
              :state="errors.length > 0 ? false : null"
              placeholder="0525112345"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Handy"
          label-for="mobile"
        >
          <validation-provider
            #default="{ errors }"
            rules="integer"
            name="Handy"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.mobileNumber"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.mobileNumber"
              :state="errors.length > 0 ? false : null"
              placeholder="01701231231"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Fax"
          label-for="fax"
        >
          <validation-provider
            #default="{ errors }"
            rules="integer"
            name="Fax"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.faxNumber"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.faxNumber"
              :state="errors.length > 0 ? false : null"
              placeholder="0525198765"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="pb-1 pt-1">
          <h5>Adresse</h5>
        </div>

        <b-form-group
          label="Strasse"
          label-for=""
        >
          <validation-provider
            #default="{ errors }"
            name="Strasse"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.street"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.street"
              :state="errors.length > 0 ? false : null"
              placeholder="Waynestrasse"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Hausnummer"
          label-for=""
        >
          <validation-provider
            #default="{ errors }"
            name="Hausnummer"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.streetNumber"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.streetNumber"
              :state="errors.length > 0 ? false : null"
              placeholder="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="PLZ"
          label-for=""
        >
          <validation-provider
            #default="{ errors }"
            name="PLZ"
            rules="required|digits:5"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.zipCode"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.zipCode"
              :state="errors.length > 0 ? false : null"
              placeholder="33104"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Stadt"
          label-for=""
        >
          <validation-provider
            #default="{ errors }"
            name="Stadt"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editCustomerEntry.city"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="customer.city"
              :state="errors.length > 0 ? false : null"
              placeholder="Paderborn"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Land"
          label-for=""
        >
          <validation-provider
            #default="{ errors }"
            name="Land"
            rules="required"
          >
            <v-select
              v-if="isEditMode"
              id="v-country"
              v-model="editCustomerEntry.country"
              :clearable="false"
              :options="countryOptions"
            />
            <v-select
              v-else
              id="v-country"
              v-model="customer.country"
              :clearable="false"
              label="title"
              :options="countryOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            @click.prevent="validationForm"
          >
            <div v-if="isEditMode">
              Aktualisieren
            </div>
            <div v-else>
              Hinzufügen
            </div>
          </b-button>

          <b-button
            type="button"
            variant="outline-secondary"
            @click="closeSidebar"
          >
            Abbrechen
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    ToastificationContent,
  },
  props: ['id', 'isEditMode', 'editCustomerEntry'],
  data() {
    return {
      customer: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        city: null,
        zipCode: null,
        street: null,
        streetNumber: null,
        country: 'Deutschland',
        isOrganization: false,
        organizationName: null,
        salutation: 'Herr',
        mobileNumber: null,
        faxNumber: null,
        billingStreet: null,
        billingCity: null,
        billingZipCode: null,
        billingCountry: null,
        billingStreetNumber: null,
      },
      salutationOptions: ['Herr', 'Frau', 'Divers'],
      countryOptions: ['Deutschland', 'Schweiz', 'Österreich'],
      required,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitCustomer()
          // 
        }
      })
    },
    closeSidebar() {
      if (this.isEditMode) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },
   
    submitCustomer() {
      // EditMode
      if (this.isEditMode) {
        this.$store
          .dispatch('updateCustomer', this.editCustomerEntry).then(() => {
          console.log('updateCustomer success')
          this.$store.dispatch('getCustomerEntries')
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
          this.toastNotification('Benutzer aktualisiert', 'CheckIcon', 'success')
        }).catch(err => {
            console.error(err)
            this.toastNotification('Benutzer konnte nicht aktualisiert werden', 'AlertTriangleIcon', 'danger')
          })
      } else {
        // AddMode
        this.$store.dispatch('addCustomer', this.customer).then(() => {
          console.log('addCustomer success')
          this.$store.dispatch('getCustomerEntries')
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
          this.toastNotification('Benutzer hinzugefügt', 'CheckIcon', 'success')
        }).catch(err => {
          console.error(err)
          this.toastNotification('Benutzer konnte nicht hinzugefügt werden', 'AlertTriangleIcon', 'danger')
        })
      }
    },
     toastNotification(title, icon, variant) {
       this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>
<style lang=""></style>
